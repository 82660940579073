.main-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 4rem;
    background-color: #121825;
    color: #dcdcdc;
    padding-bottom: 5rem;
}

.main-container-h1 {
    box-shadow: #000000 2px 2px 3px;
    border-radius: 1rem;
    padding: 0.5rem;
    margin: 5rem;
    font-size: 3.4rem;
}

.main__container {
    display: flex;
    box-shadow: rgba(24, 24, 24, 0.274) 0px 3px 7px -1px, rgba(0, 0, 0, 0.9) 0.3px 1px 2px -1px;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    border-radius: 3rem;
    padding: 4rem;
    background-color: #1d2734;
    gap: 3rem;
    width: 75%;
}

.main-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80vw;
    gap: 1rem;
    width: 90%;
}

.main-input {
    height: 3.5rem;
    border-radius: 1rem;
    border: 0.1rem solid #e0e0e0;
    background-color: #c7c7c7;
    padding: 1.5rem;
    flex: 8.5;
}

.main-add-btn {
    flex: 1.5;
    padding: 0.8rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    border: 0.1rem solid #e0e0e0;
    background-color: #dcdcdc;
    cursor: pointer;
}

.signup-btn:hover {
    background-color: #cccccc;
}

.main-tasks-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70vw;
    gap: 1rem;
    width: 100%;
}

.task-item {
    color: #121825;
    box-shadow: rgba(28, 28, 31, 0.25) 0px 3px 3px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #c7c7c7;
    padding: 0.5rem;
    border-radius: 1rem;
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
}

.task-item-span {
    flex: 8.5;
    font-size: 1.9rem;
}

/* .task-item-div {
    flex: 1.5;
} */

.checked {
    margin-right: 1rem;
    cursor: pointer;
    border: 1px solid black;
    /* background-color: #76549A; */
    background-color: white;
    padding: 0.9rem;
    border-radius: 0.5rem;
}

.input-checked {
    background-color: #76549A;
}

.task-done>span {
    text-decoration: line-through;
}

.delete-btn {
    padding: 0.1rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    border: 1px solid black;
    color: black;
    background-color: #FC5185;
    cursor: pointer;
}

@media(max-width:525px) {
    html {
        font-size: 53%;
    }

    .main__container {
        width: 92%;
    }
}