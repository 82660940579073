@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    font-family: 'Poppins', sans-serif;
    font-size: 62.6%;
}

