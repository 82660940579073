.container-main-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    gap: 1rem;
}

.login-input {
    height: 3rem;
    width: 100%;
    border-radius: 1rem;
    border: 0.1rem solid #efefef;
    padding: 0.9rem;
}

.login-btn {
    padding: 1rem;
    width: 80%;
    border-radius: 1rem;
    font-size: 1.5rem;
    border: 0.1rem solid #e0e0e0;
    background-color: #e0e0e0;
    cursor: pointer;
}

.login-btn:hover {
    background-color: #cccccc;

}

.login-h1 {
    padding-bottom: 1rem;
    font-size: 4rem;
    font-weight: 900;
}

.error-message {
    color: red;
    padding: 1rem;
    font-size: 1.4rem;
}

.login-signup {
    margin: 0.8rem;
    font-size: 1.2rem;
}